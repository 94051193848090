@import '~@anwalt.de/design-system/dist/css/extent/icons/icons.css';

i.anw-stacked-euro-sign {
  font-size: 0.5em;
  text-align: center;
  line-height: 4em;
}

i.anw-stacked-real-estate {
  font-size: 1.7rem;
  left: 0.1rem;
  line-height: 1.6em;
}

.icon-list {
  .fa-exclamation-triangle {
    top: -0.16em;
  }

  .fa-lock {
    top: -0.12em;
  }
}
